import React from 'react';

import { TPAComponentsProvider } from 'wix-ui-tpa';
// API
import { canSeeGroup, GroupWrapper } from '@wix/social-groups-api';
import { GroupControllerProps } from '../controllers/group/GroupControllerProps';
import { FeedControllerProps } from '../types/FeedControllerProps';
import { MembersControllerProps } from '../controllers/members/MembersControllerProps';
import { MemberInvitesControllerProps } from '../../../common/controllers/member-invites/MemberInvitesControllerProps';
import { WithAppToastsProps } from '../../../common/types/withAppToastsProps';
import { MainControllerProps } from '../controllers/main/MainControllerProps';
import { ActivityControllerProps } from '../controllers/activity/ActivityControllerProps';
import { NotificationSettings } from '../controllers/notifications/NotificationsControllerProps';
import { AppData } from '../contexts/AppData/IAppData';
import { ErrorHandlerControllerProps } from '../controllers/errorHandler/ErrorHandlerControllerProps';
import { EventsControllerProps } from '../controllers/events/EventsControllerProps';
import { ICommentsProps } from '../controllers/comments/ICommentsProps';
import { Tab } from '../../../common/controllers/group-url/Tab';
import { UpdateProgress } from '../../../common/ContentEditor/UpdateProgress';
import { isMobileByFormFactor } from '../../../common/utils/utils';

import { DATA_HOOKS } from './dataHooks';
import { HostContext } from '../contexts/AppSettings/HostContext';
import { OOIHost } from '../../../common/components/OOIComponent/OOIHost';
import { groupNotFound } from '../controllers/errorHandler/errorEvent';
import { ErrorOrigin } from '../controllers/errorHandler/IErrorEvent';
import { GroupNotFound } from './GroupNotFound/GroupNotFound';
import { ErrorHandlerContext } from '../contexts/ErrorHandler/ErrorHandlerContext';
import { AppDataContext } from '../contexts/AppData/AppData';
import { GroupContextProvider } from '../contexts/Group/GroupContext';
import { GroupActionsProvider } from '../contexts/GroupActions/GroupActionsContext';
import { AppToastsProvider } from '../../../common/components/AppToats/AppToastsContext';
import { ActivityContext } from '../contexts/Activity/Activity';
import { NotificationSettingsContext } from '../contexts/NotificationSettings/NotificationSettings';
import { MemberInvitesProvider } from '../../../common/context/member-invites/MemberInvitesContext';
import { EventsContext } from '../contexts/events/EventsContext';
import { WixCommentsApiProvider } from '@wix/comments-ooi-client';
import { Host } from '@wix/comments-ooi-client/dist/types/common/platform-types';
import { Group } from './Group/Group';
import {
  InjectedBiLoggerProps,
  InjectedExperimentsProps,
  withExperiments,
} from '@wix/yoshi-flow-editor';
import { ThemeProvider } from '../../../common/context/theme';
import { SiteMembersContext } from '../contexts/SiteMembers/SiteMembers';
import { IUserContext } from '../../../common/context/user/IUserContext';
import { IGroupV2 } from '../contexts/Group/IGorupV2';
import { GroupV2Providers } from '../contexts/GroupV2Providers';
import { IJoinGroupRequests } from '../controllers/joinGroupRequests/IJoinGroupRequests';
import { IMemberFollow } from 'Group/contexts/MemberFollow/IMemberFollow';
import {
  withSettings,
  WithSettingsProps,
  withStyles,
  WithStylesProps,
} from '@wix/tpa-settings/react';
import { compose } from 'common/utils/compose';
import { settingsParams } from 'Group/Settings/settingsParams';
import { stylesParams } from 'Group/Settings/styles';
import { IMembershipQuestions } from 'Group/contexts/MembershipQuestions/IMembershipQuestions';

export interface GroupWidgetState {
  error: null | string;
}

export interface GroupWidgetProps
  extends GroupControllerProps,
    FeedControllerProps,
    MembersControllerProps,
    MemberInvitesControllerProps,
    WithAppToastsProps,
    WithSettingsProps,
    WithStylesProps,
    MainControllerProps,
    ActivityControllerProps,
    NotificationSettings,
    AppData,
    InjectedBiLoggerProps,
    InjectedExperimentsProps,
    ErrorHandlerControllerProps,
    EventsControllerProps,
    ICommentsProps,
    IUserContext,
    IGroupV2,
    IJoinGroupRequests,
    IMemberFollow,
    IMembershipQuestions {}

class GroupWidgetComponent extends React.Component<
  GroupWidgetProps,
  GroupWidgetState
> {
  static displayName = 'GroupWidget';
  readonly state: GroupWidgetState = {
    error: null,
  };

  constructor(props: GroupWidgetProps, context: any) {
    super(props, context);
    if (typeof window !== 'undefined') {
      window.scrollTo(0, 0);
    }
  }

  componentDidCatch(error: any, info: any) {
    console.error(error, info);
  }

  static getDerivedStateFromError(error: any) {
    return {
      error,
    };
  }

  static defaultProps: Partial<GroupWidgetProps> = {
    actions: {} as any,
    activeTab: Tab.DISCUSSION,
    group: GroupWrapper.createEmpty(),
    loading: false,
    locale: '',
    memberInvitesLink: '',
    members: [],
    membersActions: {} as any,
    hasMoreGroupMembers: false,
    siteMembers: [],
    siteMembersMap: {},
    style: {} as any,
    uploadedRegistry: [],
    externalVideosMetadataRegistry: [],
    changeTab(): void {},
    createFeedItem(): void {},
    updateFeedItem(): void {},
    deleteFeedItem(): void {},
    pinFeedItem(): void {},
    unpinFeedItem(): void {},
    followFeedItem(): void {},
    unfollowFeedItem(): void {},
    updateProgress: UpdateProgress.STALE,
  };

  render() {
    const { host, isRTL, settings, styles } = this.props;

    const { formFactor } = host;

    const isMobile = isMobileByFormFactor(formFactor);

    if (this.state.error !== null) {
      return (
        <>
          <h1>Ooops!</h1>
          <div>{this.state.error}</div>
        </>
      );
    }

    const style = this.getSiteRootWidth() as any;

    const dir = isRTL ? 'rtl' : null;

    return (
      <div data-hook={DATA_HOOKS.root} style={style} dir={dir!}>
        <TPAComponentsProvider value={{ mobile: isMobile, rtl: isRTL }}>
          <HostContext.Provider value={host}>
            <ThemeProvider
              value={{
                buttonType: settings.get(settingsParams.buttonType),
                buttonBorderRadius: styles.get(stylesParams.buttonBorderRadius),
              }}
            >
              {this.renderGroup()}
            </ThemeProvider>
          </HostContext.Provider>
        </TPAComponentsProvider>
      </div>
    );
  }

  private renderGroup() {
    const { errorHandlers, errorEvents, host, ...rest } = this.props;
    if (errorEvents && groupNotFound(errorEvents[ErrorOrigin.Group])) {
      const height = host.dimensions.height ? host.dimensions.height : 'auto';
      return (
        <div style={{ height }}>
          <GroupNotFound
            goToGroupList={() =>
              errorHandlers.actionByError(errorEvents[ErrorOrigin.Group])
            }
          />
        </div>
      );
    }
    const {
      actions,
      style,
      isRTL,
      isEditor,
      viewMode,
      cursor,
      prevCursor,
      nextCursor,
      contextToken,
      fetchMore,
      activeTab,
      activeButton,
      activities,
      activityActions,
      apps,
      changeTab,
      createFeedItem,
      joinGroupRequestsResponse,
      deleteFeedItem,
      feedItemId,
      feedItems,
      feedTopics,
      feedFilters,
      mediaItems,
      followFeedItem,
      group,
      rules,
      feedRequest,
      loadMemberInvitesLink,
      feedLoading,
      memberInvitesLink,
      members,
      membersActions,
      hasMoreGroupMembers,
      membersQueryResponse,
      newMembers,
      badges,
      membersBadgeIds,
      notificationActions,
      notificationSettings,
      pinFeedItem,
      reactFeedItem,
      siteMembers,
      siteMembersMap,
      nonGroupMembersCount,
      toasts,
      createPostTopic,
      applyFeedFilters,
      unfollowFeedItem,
      unpinFeedItem,
      unreactFeedItem,
      updateFeedItem,
      updateProgress,
      uploadedRegistry,
      externalVideosMetadataRegistry,
      instance,
      membersUpdate,
      questionsAnswers,
      language,
      locale,
      instanceId,
      membersRequest,
      eventsContext,
      siteNavigation,
      navigateToLink,
      tabsUrls,
      ...commentsProps
    } = rest;
    return (
      <ErrorHandlerContext.Provider value={{ errorEvents, errorHandlers }}>
        <AppDataContext.Provider
          value={{
            instance,
            locale,
            instanceId,
            activeButton,
            siteNavigation,
          }}
        >
          <GroupContextProvider
            value={{
              group,
              members,
              rules,
              uploadedRegistry,
              externalVideosMetadataRegistry,
              feed: {
                createPostTopic,
                applyFeedFilters,
                feedLoading,
                contextToken,
                cursor,
                prevCursor,
                nextCursor,
                fetchMore,
                feedRequest,
                feedItems,
                feedTopics,
                feedFilters,
                mediaItems,
                deleteFeedItem,
                createFeedItem,
                updateFeedItem,
                pinFeedItem,
                unpinFeedItem,
                followFeedItem,
                unfollowFeedItem,
                reactFeedItem,
                unreactFeedItem,
              },
              updateProgress,
              apps,
            }}
          >
            <GroupActionsProvider
              value={{
                ...actions,
              }}
            >
              <SiteMembersContext.Provider
                value={{
                  membersActions,
                  hasMoreGroupMembers,
                  membersQueryResponse,
                  newMembers,
                  siteMembers,
                  siteMembersMap,
                  badges,
                  membersBadgeIds,
                  membersUpdate,
                  nonGroupMembersCount,
                  questionsAnswers,
                  membersRequest,
                }}
              >
                <AppToastsProvider value={toasts}>
                  <ActivityContext.Provider
                    value={{ activities, activityActions }}
                  >
                    <NotificationSettingsContext.Provider
                      value={{
                        notificationActions,
                        notificationSettings,
                      }}
                    >
                      <MemberInvitesProvider
                        value={{ memberInvitesLink, loadMemberInvitesLink }}
                      >
                        <EventsContext.Provider value={eventsContext}>
                          <GroupV2Providers value={this.props}>
                            <WixCommentsApiProvider
                              host={host as Host}
                              {...(commentsProps as any)}
                            >
                              <Group
                                feedItemId={feedItemId}
                                activeTab={activeTab}
                                changeTab={changeTab}
                                canSeeGroup={canSeeGroup(group)}
                                ready={!!group.groupId}
                                apps={apps}
                                group={group}
                                siteNavigation={siteNavigation}
                                navigateToLink={navigateToLink}
                                tabsUrls={tabsUrls!}
                                isRTL={isRTL!}
                                isEditor={isEditor}
                              />
                            </WixCommentsApiProvider>
                          </GroupV2Providers>
                        </EventsContext.Provider>
                      </MemberInvitesProvider>
                    </NotificationSettingsContext.Provider>
                  </ActivityContext.Provider>
                </AppToastsProvider>
              </SiteMembersContext.Provider>
            </GroupActionsProvider>
          </GroupContextProvider>
        </AppDataContext.Provider>
      </ErrorHandlerContext.Provider>
    );
  }

  private getSiteRootWidth() {
    try {
      const host = new OOIHost(this.props.host);
      if (host.isPreview() && host.isMobile()) {
        const { width, left } = host.getDimensions();
        const w = width + left * 2;

        if (w) {
          return { '--siteRootWidth': `${w}px` };
        }
      }
    } catch (e) {}
    return;
  }
}

const enhance = compose(withSettings, withExperiments, withStyles);

export const GroupWidget = enhance(
  GroupWidgetComponent,
) as React.ComponentType<GroupWidgetProps>;
