import React, { useEffect, useMemo } from 'react';
import { useBi, WidgetProps } from '@wix/yoshi-flow-editor';

import { Provider } from 'react-redux';

import { IControllerProps } from 'Group/controllers$/types';

import { GroupWidget, GroupWidgetProps } from './GroupWidget';
import { GROUP_WRAPPER } from './dataHooks';
import { useRedirect } from './hooks/useRedirect';
import { createStore } from './store';

import './project.st.css';

function Widget(props: WidgetProps<GroupWidgetProps & IControllerProps>) {
  const { group } = props;

  const bi = useBi();
  const store = useMemo(() => createStore(props.store), []);

  useRedirect({ id: group?.groupId!, slug: group?.slug! });

  useEffect(() => {
    store.dispatch({
      type: 'CONTROLLER_UPDATE',
      payload: props.store,
    });
  }, [JSON.stringify(props.store)]);

  return (
    <Provider store={store}>
      <div data-hook={GROUP_WRAPPER}>
        <GroupWidget {...props} bi={bi} />
      </div>
    </Provider>
  );
}

export default Widget;
