import { createStore as createReduxStore, combineReducers } from 'redux';

import { IRootState } from 'Group/store/types';

import { createStateReducer } from './reducer';

/**
 * Simplified version of Group/store
 * Used for data persistance only
 */
export function createStore(initialState: IRootState) {
  return createReduxStore(
    combineReducers({
      application: createStateReducer('application'),
      group: createStateReducer('group'),
      media: createStateReducer('media'),
    }),
    initialState,
  );
}
